import React from "react";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";

import {
  faArrowsRotate,
  faDatabase,
  faLinkSimple,
  faLinkSimpleSlash,
  faStore,
  faTimes,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";

import ProductImage from "../../../UI/components/ProductImage";
import { useMarketplaceProductState } from "../../hooks/useMarketplaceProductState";
import useMerchantProductLabels from "../../hooks/useMerchantProductLabels";
import { brandImage, productImage } from "../../utils";
import { Tabs } from "../Tabs";
import { AttributesTab } from "./AttributesTab";
import { VehiclesTab } from "./VehiclesTab";

const statusIcon = (status) => {
  if (status === "Linked") {
    return faLinkSimple;
  } else if (status === "Synced") {
    return faArrowsRotate;
  }
  return faLinkSimpleSlash;
};

export const DetailsModal = () => {
  const { currentProduct, setCurrentProduct, saveMerchantProduct, sendMerchantProductToPim } =
    useMarketplaceProductState();
  const { storeTitle, statusTextClass, statusBgClass } = useMerchantProductLabels(currentProduct);

  const [isSaving, setIsSaving] = useState(false);

  const productImgSrc = productImage(currentProduct);
  const brandImgSrc = brandImage(currentProduct);

  const handleSave = async () => {
    setIsSaving(true);
    const response = await saveMerchantProduct(currentProduct.id);

    // console.log("response", response);

    if (response.data) {
      toast.success("Product synced successfully");
    } else {
      toast.error("There was an error syncing the product");
      setCurrentProduct(null);
      return false;
    }

    setTimeout(() => {
      setIsSaving(false);
    }, 1500);

    return true;
  };

  const sendToPim = async () => {
    const response = await sendMerchantProductToPim(currentProduct.id);

    if (response.data) {
      toast.success("Importing product to PIM...");
    } else {
      toast.error("There was an error importing the product to PIM");
    }
  };

  return (
    <AnimatePresence>
      {currentProduct && (
        <Dialog
          static
          as={motion.div}
          open={true}
          onClose={() => setCurrentProduct(null)}
          initial={{ opacity: 0, scale: 1 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 1 }}
          className="z-5 fixed bottom-0 left-0 right-0 top-0 h-full w-full overflow-y-auto rounded-lg bg-white shadow-xl"
        >
          <Dialog.Panel
            as={motion.div}
            className="relative h-full w-full p-8"
            layoutId={currentProduct.id}
          >
            <motion.div className="grid h-full w-full grid-cols-5 gap-6">
              <motion.div className="col-span-2 flex h-full flex-col space-y-6">
                <motion.div className="aspect-square">
                  <ProductImage
                    src={productImgSrc}
                    className="h-full w-full rounded-lg border border-gray-200 object-contain object-center p-6 lg:h-full lg:w-full"
                  />
                </motion.div>
              </motion.div>
              <motion.div className="col-span-3 flex h-full w-full flex-col space-y-4">
                <div className="mt-1 text-xs font-bold uppercase tracking-wide text-gray-800">
                  <img src={brandImgSrc} className="h-auto w-[120px]" alt={currentProduct.brand} />
                </div>
                <motion.h5 className="pr-12 text-xl font-extrabold text-gray-900">
                  {currentProduct.title}
                </motion.h5>

                <div className="flex items-center space-x-2 text-[10px]">
                  <div
                    className={`rounded ${statusBgClass} space-x-1 px-2 py-1 ${statusTextClass} capitalize`}
                    title={storeTitle}
                  >
                    <FontAwesomeIcon icon={statusIcon(currentProduct.status)} />
                    <span>{currentProduct.status}</span>
                  </div>

                  <div
                    className="flex items-center space-x-1 rounded bg-indigo-50 px-2 py-1 text-indigo-500"
                    title={storeTitle}
                  >
                    <FontAwesomeIcon icon={faStore} />
                    <span>{currentProduct?.store?.name}</span>
                  </div>
                </div>

                <Tabs>
                  <div label="Attributes">
                    <AttributesTab />
                  </div>
                  <div label="Vehicles">
                    <VehiclesTab />
                  </div>
                  {/* render the variants tab if variants exist */}
                  {currentProduct.variants.length > 0 && (
                    <div label="Variants">
                      <div className="overflow-clip rounded-lg border border-gray-200 shadow">
                        <table className="w-full table-auto divide-y divide-gray-300 rounded-lg text-xs">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="whitespace-nowrap py-2.5 pl-4 pr-3 text-left font-semibold text-gray-900 sm:pl-6"
                              >
                                SKU
                              </th>
                              <th
                                scope="col"
                                className="whitespace-nowrap py-2.5 pl-4 pr-3 text-left font-semibold text-gray-900 sm:pl-6"
                              >
                                Supplier SKU
                              </th>
                              <th
                                scope="col"
                                className="py-2.5 pl-4 pr-3 text-left font-semibold text-gray-900 sm:pl-6"
                              >
                                Title
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {currentProduct.variants.map((variant, index) => (
                              <tr key={variant.sku || index}>
                                <td className="whitespace-nowrap py-2.5 pl-4 pr-3 font-medium text-gray-900 sm:pl-6">
                                  {variant.sku}
                                </td>
                                <td className="whitespace-nowrap py-2.5 pl-4 pr-3 text-gray-500 sm:pl-6">
                                  {variant.supplier_sku}
                                </td>
                                <td className="py-2.5 pl-4 pr-3 text-gray-500">{variant.title}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </Tabs>

                <motion.div className="absolute right-3 top-0 flex space-x-3">
                  {/* Send to PIM */}
                  {currentProduct.status !== "Synced" && (
                    <motion.button
                      className="flex h-8 items-center justify-center space-x-2 rounded-md bg-emerald-100 px-3 text-xs text-emerald-600 hover:bg-emerald-400 hover:text-emerald-100"
                      onClick={() => sendToPim()}
                    >
                      <span>Send to PIM</span>
                      <FontAwesomeIcon icon={faDatabase} />
                    </motion.button>
                  )}

                  <motion.button
                    className="flex h-8 w-8 items-center justify-center rounded-full bg-indigo-100 text-indigo-500 hover:bg-indigo-400 hover:text-indigo-100"
                    onClick={() => handleSave()}
                  >
                    <FontAwesomeIcon
                      icon={faArrowsRotate}
                      className={isSaving ? "animate-spin" : ""}
                    />
                  </motion.button>
                  <motion.button
                    className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-200 text-gray-500 hover:bg-gray-400 hover:text-gray-100"
                    onClick={() => setCurrentProduct(null)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </motion.button>
                </motion.div>
              </motion.div>
            </motion.div>
            <Toaster />
          </Dialog.Panel>
        </Dialog>
      )}
    </AnimatePresence>
  );
};
