import React, { useEffect, useMemo } from "react";
import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";

import { faSpinnerThird } from "@awesome.me/kit-989a8e6dbe/icons/duotone/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DefaultColumnFilter } from "./DefaultColumnFilter";
import { GlobalFilter } from "./GlobalFilter";
import { IndeterminateCheckbox } from "./IndeterminateCheckbox";

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

const getStyles = (props, align = "left") => [
  props,
  {
    className: `text-${align}`,
  },
];

export default function Table({
  columns,
  data,
  onFetchData,
  onSelectedRows = () => {},
  loading,
  refresh,
  pageCount: controlledPageCount,
  totalItems = 0,
  useRowSelection = false,
  isRowDisabled = () => false,
  initialPageSize = 20,
  rowProps = () => ({}),
  additionalFilters = [],
  globalFilterPlaceholder = null,
  globaleFilterSize = null,
  bulkActionsComponent = null,
}) {
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
      // And also our default editable cell
      // Cell: EditableCell,
      minWidth: 20,
      width: 120,
      maxWidth: 200,
    }),
    [],
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    setFilter,
    state: { pageIndex, pageSize, sortBy, filters, globalFilter, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0, pageSize: initialPageSize },
      manualSortBy: true,
      manualFilters: true,
      manualPagination: true,
      manualGlobalFilter: true,
      pageCount: controlledPageCount,
      autoResetSelectedRows: false,
      getRowId: (row) => row.id,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useResizeColumns,
    useFlexLayout,
    (hooks) => {
      if (useRowSelection) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            minWidth: 35,
            maxWidth: 35,
            width: 35,
            disableSortBy: true,
            disableResizing: true,
            disableFiltering: true,
            Header: ({ rows }) => {
              // Toggle checkboxes manually to dont toggle disabled cells
              const changeableRows = !isRowDisabled
                ? [...rows]
                : rows.filter((row) => !!isRowDisabled && !isRowDisabled(row));

              const allSelected = changeableRows.every((row) => row.isSelected);
              const allUnselected = changeableRows.every((row) => !row.isSelected);

              return (
                <IndeterminateCheckbox
                  checked={!allUnselected}
                  indeterminate={!allSelected && !allUnselected}
                  onChange={() =>
                    changeableRows.forEach((row) => row.toggleRowSelected(!allSelected))
                  }
                />
              );
            },
            Cell: ({ row }) => {
              return (
                <div>
                  <IndeterminateCheckbox
                    {...row.getToggleRowSelectedProps()}
                    disabled={row.isDisabled}
                    checked={row.isSelected || row.isDisabled}
                  />
                </div>
              );
            },
          },
          ...columns,
        ]);
      }
    },
  );

  useEffect(() => {
    onFetchData({ pageIndex, pageSize, sortBy, filters, globalFilter });
  }, [onFetchData, pageIndex, pageSize, sortBy, filters, globalFilter, refresh]);

  useEffect(() => {
    onSelectedRows(Object.keys(selectedRowIds));
  }, [selectedRowIds]);

  return (
    <div className="flex min-h-[60dvh] flex-col">
      <div className="overflow-x-auto">
        <div className="inline-block w-full min-w-full py-2 align-middle">
          {/* <div className="border-b border-gray-200 bg-white p-5 shadow-sm sm:rounded-md"> */}
          <div className="mb-5 flex items-start justify-start gap-2">
            <GlobalFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              placeholder={globalFilterPlaceholder}
              size={globaleFilterSize}
            />
            {additionalFilters.map((f, index) =>
              React.cloneElement(
                f({
                  filters,
                  setFilter,
                }),
                { key: index },
              ),
            )}
            {bulkActionsComponent && (
              <div className="flex flex-1 justify-end pr-2">
                <bulkActionsComponent.type {...bulkActionsComponent.props} />
              </div>
            )}
          </div>

          <div className="h-[55dvh] w-full overflow-auto">
            <div {...getTableProps()} className="border-separate divide-y divide-gray-200">
              <div className="sticky top-0 border-b border-gray-200 bg-white">
                {headerGroups.map((headerGroup, index) => (
                  <div key={index} {...headerGroup.getHeaderGroupProps({})} className="tr">
                    {headerGroup.headers.map((column, index) => (
                      <div key={index} {...column.getHeaderProps({})} className="th">
                        <div className="flex items-center" {...column.getSortByToggleProps()}>
                          <div className="overflow-hidden text-ellipsis whitespace-nowrap px-2 py-3 text-[10px] font-bold uppercase tracking-wide text-gray-600">
                            <span className="">{column.render("Header")}</span>
                          </div>
                          <div className="mr-2 text-indigo-500">
                            {column.disableSortBy ? (
                              <></>
                            ) : (
                              <i
                                className={`${
                                  column.isSorted
                                    ? column.isSortedDesc
                                      ? "fas fa-caret-down"
                                      : "fas fa-caret-up"
                                    : "fad fa-sort text-gray-300"
                                }`}
                              ></i>
                            )}
                          </div>
                        </div>
                        {/* Render the columns filter UI */}
                        <div>{column.canFilter ? column.render("Filter") : null}</div>
                        {column.canResize && (
                          <div
                            {...column.getResizerProps()}
                            className={`absolute right-0 top-0 h-full w-[1px] bg-gray-200 ${
                              column.isResizing ? "isResizing" : ""
                            }`}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div className="text-xs text-gray-500">
                {loading && (
                  <div className="flex items-center justify-center gap-2 py-3 text-indigo-600">
                    <span>Loading</span>
                    <FontAwesomeIcon icon={faSpinnerThird} className="h-3 w-3 animate-spin" />
                  </div>
                )}
                {page.map((row, index) => {
                  const rowWithCustomProp = Object.assign(row, {
                    isDisabled: !!isRowDisabled && isRowDisabled(row),
                  });

                  prepareRow(rowWithCustomProp);
                  return (
                    <div
                      key={index}
                      {...row.getRowProps([
                        {
                          className: "even:bg-gray-50",
                        },
                        rowProps(row),
                      ])}
                    >
                      {row.cells.map((cell, index) => {
                        return (
                          <div
                            key={index}
                            {...cell.getCellProps([
                              {
                                className: cell.column.className,
                                style: cell.column.style,
                              },
                              {
                                className:
                                  "overflow-hidden text-ellipsis whitespace-nowrap align-middle py-2.5 px-2",
                              },
                            ])}
                            title={cell.value?.toString()}
                          >
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <nav
            className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
            aria-label="Pagination"
          >
            <div className="hidden sm:block">
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{pageIndex * pageSize + 1}</span> to{" "}
                <span className="font-medium">
                  {Math.min(totalItems, (pageIndex + 1) * pageSize)}
                </span>{" "}
                of <span className="font-medium">{totalItems}</span> results
              </p>
            </div>
            <div className="flex flex-1 justify-between sm:justify-end">
              <button
                type="button"
                onClick={() => previousPage()}
                disabled={loading || !canPreviousPage}
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Previous
              </button>
              <button
                type="button"
                onClick={() => nextPage()}
                disabled={loading || !canNextPage}
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Next
              </button>
            </div>
          </nav>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}
