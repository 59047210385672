import React, { useState } from "react";

import { faPencil } from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import InputField from "@/common/components/InputField";

export const ShipmentParty = ({ party, partyType, partyLabel, control }) => {
  const [isEditing, setEditing] = useState(false);

  return (
    <>
      <div className="flex items-center justify-between pb-2">
        <h2 className="heading-sm">{partyLabel}</h2>
        <button
          onClick={() => setEditing(!isEditing)}
          className="flex items-start text-indigo-500 hover:text-indigo-700"
          aria-label="Edit Sender"
        >
          <FontAwesomeIcon icon={faPencil} className="h-4 w-4" />
        </button>
      </div>
      {isEditing ? (
        <div className="flex flex-col space-y-1">
          <div className="flex space-x-2">
            <InputField
              name={`${partyType}.company`}
              control={control}
              placeholder="Company"
              className="!w-full"
            />
            <InputField
              name={`${partyType}.name`}
              control={control}
              placeholder="Contact Name"
              className="!w-full"
            />
          </div>
          <div className="flex space-x-2">
            <InputField
              name={`${partyType}.email`}
              control={control}
              placeholder="Email"
              className="!w-full"
            />
            <InputField
              name={`${partyType}.phone`}
              control={control}
              placeholder="Phone"
              className="!w-full"
            />
          </div>
          <hr />
          <InputField
            name={`${partyType}.address1`}
            control={control}
            placeholder="Address Line 1"
          />
          <InputField
            name={`${partyType}.address2`}
            control={control}
            placeholder="Address Line 2"
          />
          <div className="flex space-x-2">
            <InputField
              name={`${partyType}.city`}
              control={control}
              placeholder="City/Town/Suburb"
              className="!w-full"
            />
            <InputField
              name={`${partyType}.subdivision_code`}
              control={control}
              placeholder="State/Territory/Province"
              className="!w-full"
            />
            <InputField
              name={`${partyType}.postal_code`}
              control={control}
              placeholder="Postal Code"
              className="!w-full"
            />
          </div>
          <InputField name={`${partyType}.country`} control={control} placeholder="Country" />
        </div>
      ) : (
        <>
          <div className="mb-2 text-sm leading-tight">
            <p>{party.address1}</p>
            <p>{party.address2}</p>
            <p className="uppercase">
              {party.city} {party.subdivision_code} {party.postal_code}
            </p>
            <p>{party.country}</p>
          </div>
          <div className="text-sm">
            <p>{party.name}</p>

            <p className="text-xs text-gray-400">
              {party.email} / {party.phone}
            </p>
          </div>
        </>
      )}
    </>
  );
};
