import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { faArrowRight } from "@awesome.me/kit-989a8e6dbe/icons/classic/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  getLocationsListQueryOptions,
  useFulfillmentsStatistics,
  useLocationsList,
  useShipmentsStatistics,
} from "@/apis/distributor";

export const loader = (queryClient) => async () => {
  return await queryClient.ensureQueryData(getLocationsListQueryOptions());
};

export default function Dashboard() {
  const { data: distributorLocations } = useLocationsList();

  const distributorLocationsIds = distributorLocations?.map((location) => location.id);

  const { data: shipmentsStatistics } = useShipmentsStatistics(
    {
      location_ids: distributorLocationsIds,
    },
    {
      enabled: !!distributorLocationsIds,
    },
  );

  const { data: fulfillmentsStatistics } = useFulfillmentsStatistics(
    {
      location_ids: distributorLocationsIds,
    },
    {
      enabled: !!distributorLocationsIds,
    },
  );

  return (
    <div className="grid grid-cols-1 gap-5">
      {distributorLocations?.map((location) => {
        const locationShipmentsStatistics = shipmentsStatistics?.locations?.find(
          (locationStatistics) => locationStatistics.location_id === location.id,
        );
        const locationFulfillmentsStatistics = fulfillmentsStatistics?.locations?.find(
          (locationStatistics) => locationStatistics.location_id === location.id,
        );

        return (
          <div className="rounded-xl border border-gray-200 p-4" key={location.id}>
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-semibold">{location.name}</h2>
              <span className="rounded bg-indigo-100 p-1 text-xs font-semibold uppercase text-indigo-600">
                {location.code}
              </span>
            </div>
            <div className="mt-4 grid grid-cols-3 gap-4">
              <RouterLink to={`/locations/${location.id}/fulfillments?page=1&page_size=10`}>
                <div className="rounded-lg bg-white p-4 shadow-lg transition-shadow duration-300 hover:shadow-xl">
                  <div className="flex items-start justify-between">
                    <h3 className="mb-4 font-semibold text-gray-900">Fulfillment Requests</h3>
                    <span>
                      <FontAwesomeIcon icon={faArrowRight} className="text-gray-500" />
                    </span>
                  </div>
                  <dl className="grid grid-cols-3 gap-4">
                    {[
                      {
                        label: "Pending",
                        value: locationFulfillmentsStatistics?.pending,
                        labelColor: "text-yellow-600",
                        color: "text-yellow-800",
                        bgColor: "bg-yellow-100",
                      },
                      {
                        label: "In Progress",
                        value: locationFulfillmentsStatistics?.in_progress,
                        labelColor: "text-purple-600",
                        color: "text-purple-800",
                        bgColor: "bg-purple-100",
                      },
                      {
                        label: "Completed",
                        value: locationFulfillmentsStatistics?.complete,
                        labelColor: "text-green-600",
                        color: "text-green-800",
                        bgColor: "bg-green-100",
                      },
                    ].map(({ label, value, color, labelColor, bgColor }) => (
                      <div
                        key={label}
                        className={`flex flex-col items-center rounded-md ${bgColor} p-2`}
                      >
                        <dt className={`mb-1 text-sm font-medium ${labelColor}`}>{label}</dt>
                        <dd className={`text-2xl font-bold ${color}`}>{value}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </RouterLink>
              <RouterLink to={`/locations/${location.id}/shipments?page=1&page_size=10`}>
                <div className="rounded-lg bg-white p-4 shadow-lg transition-shadow duration-300 hover:shadow-xl">
                  <div className="flex items-start justify-between">
                    <h3 className="mb-4 font-semibold text-gray-900">Shipments</h3>
                    <span>
                      <FontAwesomeIcon icon={faArrowRight} className="text-gray-500" />
                    </span>
                  </div>
                  <dl className="grid grid-cols-4 gap-4">
                    {[
                      {
                        label: "Pending",
                        value: locationShipmentsStatistics?.pending,
                        labelColor: "text-yellow-600",
                        color: "text-yellow-800",
                        bgColor: "bg-yellow-100",
                      },
                      {
                        label: "Quoted",
                        value: locationShipmentsStatistics?.quoted,
                        labelColor: "text-blue-600",
                        color: "text-blue-800",
                        bgColor: "bg-blue-100",
                      },
                      {
                        label: "Booked",
                        value: locationShipmentsStatistics?.booked,
                        labelColor: "text-purple-600",
                        color: "text-purple-800",
                        bgColor: "bg-purple-100",
                      },
                      {
                        label: "Shipped",
                        value: locationShipmentsStatistics?.shipped,
                        labelColor: "text-green-600",
                        color: "text-green-800",
                        bgColor: "bg-green-100",
                      },
                    ].map(({ label, value, color, labelColor, bgColor }) => (
                      <div
                        key={label}
                        className={`flex flex-col items-center rounded-md ${bgColor} p-2`}
                      >
                        <dt className={`mb-1 text-sm font-medium ${labelColor}`}>{label}</dt>
                        <dd className={`text-2xl font-bold ${color}`}>{value}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </RouterLink>
            </div>
          </div>
        );
      })}
    </div>
  );
}
