import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxOpen,
  faStoreAlt,
  faTruckFast,
  faCheck,
} from "@awesome.me/kit-989a8e6dbe/icons/classic/light";
import React from "react";
import { faDatabase } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import ProductImage from "../../../UI/components/ProductImage";
import clsx from "clsx";

export default function ProductCard({
  product,
  onViewMore,
  onSelected,
  isSelected = false,
  isExactMatch = false,
  isImported = false,
  isPartiallyImported = false,
  importedStores = [],
}) {
  const importedStoreFormatted = importedStores
    .map((store) => `${store.name} (${store.platform})`)
    .join(", ");
  const importedCountFormatted = `${importedStores.length} ${
    importedStores.length > 1 ? "stores" : "store"
  }`;

  const hoverMessage =
    isPartiallyImported || isImported ? `Imported to ${importedStoreFormatted}` : "";
  const labelMessage =
    isPartiallyImported || isImported ? `Imported to ${importedCountFormatted}` : "";

  const highlightClass = () => {
    if (isExactMatch) return "ring-indigo-500/20 ring-4";
    if (isImported || isPartiallyImported) return "ring-success-300/50 ring-4";
    return "";
  };

  return (
    <div className="group relative h-full w-full">
      <div className="z-10 h-full min-h-max">
        <div
          className={`relative flex h-full w-full flex-col justify-between space-y-3 rounded-lg  border border-gray-400/20 bg-white p-2 shadow transition hover:shadow-xl hover:shadow-indigo-900/20 group-hover:z-20 ${highlightClass()}`}
        >
          <div className="relative aspect-square overflow-hidden">
            <ProductImage
              src={`https://res.cloudinary.com/proex/image/fetch/f_auto,w_600,h_600,c_pad,b_white,q_80,dpr_auto/${product.image}`}
              className="pointer-events-none h-full w-full rounded-lg bg-white object-cover object-center transition duration-300 group-hover:opacity-30 lg:h-full lg:w-full"
            />
            <div
              onClick={() => onViewMore(product)}
              className="absolute bottom-0 left-0 right-0 top-0 mx-auto flex cursor-pointer items-center justify-center opacity-0 transition duration-300 group-hover:opacity-100"
            >
              <button className="btn">View Product</button>
            </div>
          </div>
          {!isImported && (
            <div className="absolute left-2 top-0 z-10 flex cursor-pointer items-center rounded-br-lg">
              <input
                type="checkbox"
                checked={isSelected}
                onChange={() => onSelected(product)}
                className="h-5 w-5 rounded border-gray-300 text-indigo-600 ring-4 ring-white focus:ring-indigo-500"
              />
            </div>
          )}
          <div className="absolute -top-1 right-2 flex flex-col items-end space-y-2">
            {isExactMatch && (
              <div className="space-x-2 rounded bg-indigo-50 px-2 py-1 text-[10px] text-indigo-600">
                <FontAwesomeIcon icon={faCheck} />
                <span>Match</span>
              </div>
            )}
            {(isPartiallyImported || isImported) && (
              <div
                className="bg-success-50 text-success-600 ml-2 space-x-2 rounded px-2 py-1 text-[10px]"
                title={hoverMessage}
              >
                <FontAwesomeIcon icon={faCheck} />
                <span>{labelMessage}</span>
              </div>
            )}
          </div>

          <div className="mt-1 flex flex-col items-start space-y-2">
            {product.brand_logo !== "" && (
              <img src={product.brand_logo} className="h-[16px] w-auto" alt={product.brand} />
            )}
            <h5 className="block text-xs font-medium text-gray-500">{product.title}</h5>
          </div>

          <div className="flex items-center justify-start space-x-2 text-[10px]">
            <span className="space-x-2 rounded bg-purple-50 px-2 py-1 text-purple-600">
              <FontAwesomeIcon icon={faStoreAlt} />
              <span>{product.stores}</span>
            </span>
            {product.has_distribution ? (
              <>
                <span
                  className={`${
                    product.inventory > 0
                      ? "bg-purple-50 text-purple-600"
                      : "bg-danger-50 text-danger-600"
                  } space-x-2 rounded px-2 py-1`}
                >
                  <FontAwesomeIcon icon={faBoxOpen} />
                  <span>{product.inventory}</span>
                </span>
              </>
            ) : (
              <span className="text-warning-600 bg-warning-50 space-x-2 rounded px-2 py-1">
                <FontAwesomeIcon icon={faDatabase} />
                <span>Data only</span>
              </span>
            )}
            {/* status */}
            <span
              className={clsx(
                "space-x-2 rounded px-2 py-1 capitalize",
                product.status === "published" && "bg-success-50 text-success-600",
                product.status === "invalid" && "bg-warning-50 text-warning-600"
              )}
            >
              {product.status}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
