import React, { Fragment, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { TableFlowImporter } from "@tableflow/react";
import clsx from "clsx";

const options = [
  {
    label: "Import Products",
    import_type: "product",
    importer_id: "ec3fdb4c-6b96-4e23-98c2-3d9029977601",
    template: null,
  },
  {
    label: "Import Packaging",
    import_type: "packaging",
    importer_id: "bf1f064e-0faa-45c2-a75c-c6618b30f506",
    template: null,
  },
  // {
  //   label: "Import Media",
  //   import_type: "Media",
  //   importer_id: "13f40548-96a8-4bb3-80c8-1f7c1d5cbc32",
  //   template: null,
  // },
  {
    label: "Import Vehicle Fitments",
    import_type: "product_vehicles",
    importer_id: "b58fb7ea-5f78-4ba2-a4c6-3e8cfe848023",
    template: null,
  },
  {
    label: "Import Product Attributes",
    import_type: "product_attributes",
    importer_id: "9faa7746-89d3-4ae4-8857-3086463aa962",
    template: null,
  },
];

export default function ProductImport({ user, team, brand }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  return (
    <>
      <Menu as="div" className="relative ml-3 inline-block text-left">
        <div>
          <Menu.Button className="btn btn-info">
            Import
            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {options.map((option) => (
                <Menu.Item key={option.importer_id}>
                  {({ active }) => (
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedOption(option);
                        setIsOpen(true);
                      }}
                      className={clsx(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block w-full px-4 py-2 text-left text-sm disabled:text-gray-300",
                      )}
                      // disabled={option.template === null}
                    >
                      {option.label}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      {selectedOption && selectedOption?.importer_id && (
        <TableFlowImporter
          importerId={selectedOption.importer_id}
          modalIsOpen={isOpen}
          modalOnCloseTriggered={() => setIsOpen(false)}
          darkMode={false}
          // template={selectedOption.template}
          metadata={{
            user_id: user.id,
            team_id: team.id,
            brand_id: brand.id,
            import_type: selectedOption.import_type,
          }}
          onComplete={(data, error) => {
            console.log(data);

            if (error) {
              console.log(error);
              toast.error(error);
            } else {
              setIsOpen(false);
              toast.success(
                "Import successful. You'll receive an email when we've finished processing your data.",
              );
            }
          }}
        />
      )}

      <Toaster />
    </>
  );
}
