/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Partbot Distributor API
 * OpenAPI spec version: v1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  CancellationRequestUpdate200,
  CancellationRequestUpdateBody,
  Fulfillment,
  FulfillmentRequestUpdate200,
  FulfillmentRequestUpdateBody,
  FulfillmentsListParams,
  FulfillmentsStatistics200Item,
  FulfillmentsStatisticsParams
} from '../../schemas'
import { customInstance } from '../../axios-instance';
import type { ErrorType } from '../../axios-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;



/**
 * List all fulfillments for a location
 * @summary List Fulfillments
 */
export const fulfillmentsList = (
    params?: FulfillmentsListParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<Fulfillment[]>(
      {url: `/fulfillments`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getFulfillmentsListQueryKey = (params?: FulfillmentsListParams,) => {
    return [`/fulfillments`, ...(params ? [params]: [])] as const;
    }

    
export const getFulfillmentsListQueryOptions = <TData = Awaited<ReturnType<typeof fulfillmentsList>>, TError = ErrorType<unknown>>(params?: FulfillmentsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof fulfillmentsList>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFulfillmentsListQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fulfillmentsList>>> = ({ signal }) => fulfillmentsList(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fulfillmentsList>>, TError, TData> & { queryKey: QueryKey }
}

export type FulfillmentsListQueryResult = NonNullable<Awaited<ReturnType<typeof fulfillmentsList>>>
export type FulfillmentsListQueryError = ErrorType<unknown>

/**
 * @summary List Fulfillments
 */
export const useFulfillmentsList = <TData = Awaited<ReturnType<typeof fulfillmentsList>>, TError = ErrorType<unknown>>(
 params?: FulfillmentsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof fulfillmentsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFulfillmentsListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Get statistics for fulfillments by location IDs
 * @summary Get Fulfillment Statistics
 */
export const fulfillmentsStatistics = (
    params?: FulfillmentsStatisticsParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<FulfillmentsStatistics200Item[]>(
      {url: `/fulfillments/statistics`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getFulfillmentsStatisticsQueryKey = (params?: FulfillmentsStatisticsParams,) => {
    return [`/fulfillments/statistics`, ...(params ? [params]: [])] as const;
    }

    
export const getFulfillmentsStatisticsQueryOptions = <TData = Awaited<ReturnType<typeof fulfillmentsStatistics>>, TError = ErrorType<unknown>>(params?: FulfillmentsStatisticsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof fulfillmentsStatistics>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFulfillmentsStatisticsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fulfillmentsStatistics>>> = ({ signal }) => fulfillmentsStatistics(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fulfillmentsStatistics>>, TError, TData> & { queryKey: QueryKey }
}

export type FulfillmentsStatisticsQueryResult = NonNullable<Awaited<ReturnType<typeof fulfillmentsStatistics>>>
export type FulfillmentsStatisticsQueryError = ErrorType<unknown>

/**
 * @summary Get Fulfillment Statistics
 */
export const useFulfillmentsStatistics = <TData = Awaited<ReturnType<typeof fulfillmentsStatistics>>, TError = ErrorType<unknown>>(
 params?: FulfillmentsStatisticsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof fulfillmentsStatistics>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFulfillmentsStatisticsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Get fulfillment by ID for a location
 * @summary Get Fulfillment
 */
export const fulfillmentById = (
    id: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<Fulfillment>(
      {url: `/fulfillments/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getFulfillmentByIdQueryKey = (id: number,) => {
    return [`/fulfillments/${id}`] as const;
    }

    
export const getFulfillmentByIdQueryOptions = <TData = Awaited<ReturnType<typeof fulfillmentById>>, TError = ErrorType<unknown>>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof fulfillmentById>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFulfillmentByIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fulfillmentById>>> = ({ signal }) => fulfillmentById(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fulfillmentById>>, TError, TData> & { queryKey: QueryKey }
}

export type FulfillmentByIdQueryResult = NonNullable<Awaited<ReturnType<typeof fulfillmentById>>>
export type FulfillmentByIdQueryError = ErrorType<unknown>

/**
 * @summary Get Fulfillment
 */
export const useFulfillmentById = <TData = Awaited<ReturnType<typeof fulfillmentById>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof fulfillmentById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFulfillmentByIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Accept or reject a fulfillment request for a fulfillment
 * @summary Update Fulfillment Request
 */
export const fulfillmentRequestUpdate = (
    id: string,
    fulfillmentRequestUpdateBody: FulfillmentRequestUpdateBody,
 ) => {
      
      
      return customInstance<FulfillmentRequestUpdate200>(
      {url: `/fulfillments/${id}/fulfillment_request`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: fulfillmentRequestUpdateBody
    },
      );
    }
  


export const getFulfillmentRequestUpdateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fulfillmentRequestUpdate>>, TError,{id: string;data: FulfillmentRequestUpdateBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof fulfillmentRequestUpdate>>, TError,{id: string;data: FulfillmentRequestUpdateBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof fulfillmentRequestUpdate>>, {id: string;data: FulfillmentRequestUpdateBody}> = (props) => {
          const {id,data} = props ?? {};

          return  fulfillmentRequestUpdate(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type FulfillmentRequestUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof fulfillmentRequestUpdate>>>
    export type FulfillmentRequestUpdateMutationBody = FulfillmentRequestUpdateBody
    export type FulfillmentRequestUpdateMutationError = ErrorType<unknown>

    /**
 * @summary Update Fulfillment Request
 */
export const useFulfillmentRequestUpdate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fulfillmentRequestUpdate>>, TError,{id: string;data: FulfillmentRequestUpdateBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof fulfillmentRequestUpdate>>,
        TError,
        {id: string;data: FulfillmentRequestUpdateBody},
        TContext
      > => {

      const mutationOptions = getFulfillmentRequestUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Accept or reject a cancellation request for a fulfillment
 * @summary Update Cancellation Request
 */
export const cancellationRequestUpdate = (
    id: string,
    cancellationRequestUpdateBody: CancellationRequestUpdateBody,
 ) => {
      
      
      return customInstance<CancellationRequestUpdate200>(
      {url: `/fulfillments/${id}/cancellation_request`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: cancellationRequestUpdateBody
    },
      );
    }
  


export const getCancellationRequestUpdateMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancellationRequestUpdate>>, TError,{id: string;data: CancellationRequestUpdateBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof cancellationRequestUpdate>>, TError,{id: string;data: CancellationRequestUpdateBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancellationRequestUpdate>>, {id: string;data: CancellationRequestUpdateBody}> = (props) => {
          const {id,data} = props ?? {};

          return  cancellationRequestUpdate(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CancellationRequestUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof cancellationRequestUpdate>>>
    export type CancellationRequestUpdateMutationBody = CancellationRequestUpdateBody
    export type CancellationRequestUpdateMutationError = ErrorType<unknown>

    /**
 * @summary Update Cancellation Request
 */
export const useCancellationRequestUpdate = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancellationRequestUpdate>>, TError,{id: string;data: CancellationRequestUpdateBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof cancellationRequestUpdate>>,
        TError,
        {id: string;data: CancellationRequestUpdateBody},
        TContext
      > => {

      const mutationOptions = getCancellationRequestUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    