import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromDottedLine } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { Header } from "./components/Header";
import { ImportList } from "./components/ImportListing/ImportList";
import { BrandSellerAgreementModal } from "./components/ImportListing/BrandSellerAgreementModal";
import { ImportListSkeleton } from "./components/ImportListing/ImportListSkeleton";
import { Pagination } from "./components/Pagination";
import { PublishListingsModal } from "./components/ImportListing/PublishListingsModal";
import { PublishSuccessfulModal } from "./components/ImportListing/PublishSuccessfulModal";
import { Sidebar } from "./components/Sidebar";
import { useMarketplaceImportState } from "./hooks/useMarketplaceImportState";
import { MarketplaceImportStore } from "./state/MarketplaceImportStore";
import { StoreProvider } from "./state/StoreProvider";
import { BillingRequiredModal } from "./components/BillingRequiredModal";
import { useInterval } from "./hooks/useInterval";
import { JobProgressBar } from "./components/JobProgressBar";
import { Button } from "../UI/components";
import { SearchBar } from "./components/MerchantProducts/SearchBar";
import { faTrashAlt } from "@awesome.me/kit-989a8e6dbe/icons/classic/light";
import { RemoveListingsModal } from "./components/ImportListing/RemoveListingsModal";

const FETCH_PROGRESS_INTERVAL = 1000;

const MarketplaceImport = ({ team, app_contexts }) => {
  const {
    importList,
    importListLoading,
    totalRows,
    currentPage,
    perPage,
    isBulkPublishModalOpen,
    publishLoading,
    publishErrors,
    isPublishSuccessful,
    publishedStores,
    setPublishSuccessModal,
    setBulkPublishModal,
    fetchImportListings,
    fetchStores,
    publishBulkListings,
    removeBulkListings,
    brandSellerAgreements,
    merchantProductsCount,
    bulkPublishJobId,
    fetchBulkPublishProgress,
    bulkPublishProgress,
    bulkPublishTotal,
    bulkPublishAt,
    searchQuery,
  } = useMarketplaceImportState();

  const [isBrandSellerAgreementModalOpen, setIsBrandSellerAgreementModalOpen] = useState(false);
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const [isFetchingBulkImportProgress, setIsFetchingBulkImportProgress] = useState(false);
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const scrollRef = useRef(null);
  const scrollToTop = () => scrollRef.current.scrollIntoView();

  useEffect(() => {
    fetchImportListings(currentPage, perPage);
    fetchStores();
  }, []);

  const importComplete = useCallback(async () => {
    const response = await fetchImportListings(currentPage, perPage);
    if (response?.status === 200) {
      scrollToTop();
      setPublishSuccessModal(false);
    }
  }, [currentPage, perPage, fetchImportListings, setPublishSuccessModal]);

  const handleSearch = async (query) => {
    const response = await fetchImportListings(1, perPage, query);
    if (response?.status === 200) {
      scrollToTop();
    }
  };

  const handleChangePage = async (page) => {
    const response = await fetchImportListings(page, perPage);
    if (response?.status === 200) {
      scrollToTop();
    }
  };

  const handleBulkPublish = async () => {
    await publishBulkListings();
    setIsFetchingBulkImportProgress(true);
  };

  const checkBrandSellerAgreements = () => {
    console.log("brandSellerAgreements", brandSellerAgreements);
    if (!!brandSellerAgreements.length) {
      setIsBrandSellerAgreementModalOpen(true);
    } else {
      setBulkPublishModal(true);
    }
  };

  const handleImportAll = () => {
    if (team.subscription_active) {
      checkBrandSellerAgreements();
    } else {
      setIsBillingModalOpen(true);
    }
  };

  const handleRemoveAll = async () => {
    setIsRemoving(true);
    const response = await removeBulkListings();
    if (response?.status === 200) {
      scrollToTop();
      setIsRemoving(false);
    }
    setIsRemovalModalOpen(false);
  };

  useInterval(
    async () => {
      const response = await fetchBulkPublishProgress();
      if (response?.status === 200) {
        if (response?.data?.progress == null) {
          setIsFetchingBulkImportProgress(false);
        }
        if (response?.data?.progress === 100) {
          const response = await fetchImportListings(1, perPage);
          if (response?.status === 200) {
            scrollToTop();
            setIsFetchingBulkImportProgress(false);
            // resetSyncProgress();
          }
        }
      } else {
        setIsFetchingBulkImportProgress(false);
      }
    },
    isFetchingBulkImportProgress ? FETCH_PROGRESS_INTERVAL : null
  );

  return (
    <div ref={scrollRef}>
      <Header section="Import Listings" app_contexts={app_contexts} />
      <section className="relative flex min-h-screen w-full">
        <div className="grid h-auto w-full grid-cols-10 gap-6">
          <div className="col-span-2">
            <Sidebar
              team={team}
              importListCount={totalRows}
              merchantProductsCount={merchantProductsCount}
            />
          </div>
          <div className="col-span-8 flex h-full flex-col space-y-8">
            <div className="flex w-full items-center justify-between">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">Import List</h2>

              {importList && importList.length > 0 && (
                <div title="bulk actions" className="btn-group">
                  <Button
                    icon={faTrashAlt}
                    onClick={() => setIsRemovalModalOpen(true)}
                    label="Remove All"
                    className="btn btn-danger"
                  />
                  <Button
                    icon={faArrowUpFromDottedLine}
                    onClick={handleImportAll}
                    label="Import All"
                  />
                </div>
              )}
            </div>

            {importList && importList.length > 0 && (
              <section className="flex w-full space-x-3 rounded-lg bg-white p-3 shadow">
                <div className="relative flex sm:w-1/2 lg:w-2/5">
                  <SearchBar
                    placeholder="Search Import List by SKU"
                    value={searchQuery}
                    onSubmit={handleSearch}
                  />
                </div>
              </section>
            )}

            {isFetchingBulkImportProgress && (
              <section>
                <JobProgressBar
                  progress={bulkPublishProgress}
                  total={bulkPublishTotal}
                  at={bulkPublishAt}
                  actionName="Importing"
                  actionPast="imported"
                />
              </section>
            )}

            {importList && (
              <section className="flex flex-col space-y-5">
                {importListLoading ? (
                  <ImportListSkeleton itemsCount={Math.min(perPage, totalRows)} />
                ) : (
                  importList.map((item) => {
                    return <ImportList key={item.id} item={item} team={team} />;
                  })
                )}
              </section>
            )}

            {!importList.length && (
              <section className="prose text-gray-500">
                <p>
                  Products you add to your Import List will appear here.{" "}
                  <a href="/" className="link">
                    Add Products
                  </a>
                </p>
              </section>
            )}

            {totalRows > perPage && (
              <section className="pb-4">
                <Pagination
                  currentPage={currentPage}
                  totalRows={totalRows}
                  perPage={perPage}
                  onChangePage={handleChangePage}
                />
              </section>
            )}
          </div>
        </div>
      </section>

      <BrandSellerAgreementModal
        isOpen={isBrandSellerAgreementModalOpen}
        onClose={() => setIsBrandSellerAgreementModalOpen(false)}
        onConfirm={() => {
          setIsBrandSellerAgreementModalOpen(false);
          setBulkPublishModal(true);
        }}
        agreements={brandSellerAgreements}
      />
      <PublishListingsModal
        title={"Import All Listings"}
        description={"This action will import every listing to your selected store"}
        isOpen={isBulkPublishModalOpen}
        onClose={() => !publishLoading && setBulkPublishModal(false)}
        onConfirm={handleBulkPublish}
        loading={publishLoading}
        error={publishErrors}
      />
      <PublishSuccessfulModal
        isOpen={isPublishSuccessful}
        onClose={importComplete}
        publishedStores={publishedStores}
      />
      <RemoveListingsModal
        isOpen={isRemovalModalOpen}
        onCancel={() => setIsRemovalModalOpen(false)}
        onAccept={() => handleRemoveAll()}
        loading={isRemoving}
      />
      <BillingRequiredModal
        isOpen={isBillingModalOpen}
        onClose={() => setIsBillingModalOpen(false)}
        onClick={() => {
          window.location.pathname = "/billing";
        }}
      />
    </div>
  );
};

const MarketplaceImportContainer = ({ children, ...props }) => {
  const store = MarketplaceImportStore({
    apiKey: props.api_key,
    location: location,
    totalRows: props.import_listing_count,
    merchantProductsCount: props.merchant_products_count,
  });

  return (
    <StoreProvider store={store}>
      <MarketplaceImport {...props}>{children}</MarketplaceImport>
    </StoreProvider>
  );
};

export default MarketplaceImportContainer;
