import produce from "immer";
import { createWithEqualityFn } from "zustand/traditional";

import Api from "../api";

const initialState = {
  importListingCount: 0,
  team: null,
  currentPage: 1,
  perPage: 20,
  products: [],
  totalRows: 0,
  totalHits: 0,
  productsLoading: false,
  productsError: null,
  currentProduct: null,
  currentProductLoading: false,
  currentProductError: null,
  searchQuery: "",
  syncJobIds: [],
  syncProgress: null,
  syncTotal: null,
  syncAt: null,
  stores: [],
};

export const MarketplaceProductStore = (preloadedState = initialState) => {
  const marketplaceAPI = new Api(preloadedState.apiKey, preloadedState.location);

  return createWithEqualityFn((set, get) => ({
    ...initialState,
    ...preloadedState,
    fetchProducts: async (page, perPage, query, store, brand, status, published) => {
      set(
        produce((draft) => {
          draft.productsLoading = true;
          draft.productsError = null;
          draft.searchQuery = query;
          draft.brand = brand;
          draft.status = status;
          draft.published = published;
        }),
      );

      const response = await marketplaceAPI.loadMerchantProducts(
        page,
        perPage,
        query,
        store,
        brand,
        status,
        published,
      );

      if (response.status === 200) {
        set(
          produce((draft) => {
            draft.products = response?.data?.data;
            draft.perPage = parseInt(response?.data?.meta?.per_page);
            draft.currentPage = parseInt(response?.data.meta?.page);
            draft.totalRows = parseInt(response?.data.meta?.total_items);
            draft.totalHits = parseInt(response?.data.meta?.total_hits);
            draft.syncJobIds = response?.data?.meta?.sync_job_ids;
            draft.stores = response?.data?.meta?.stores;
            draft.productsLoading = false;
            draft.productsError = null;
            draft.currentProduct = null;
            draft.currentProductLoading = false;
            draft.currentProductError = null;
          }),
        );
      } else {
        set(
          produce((draft) => {
            draft.productsLoading = false;
            draft.productsError = response.data;
          }),
        );
      }

      return response;
    },

    fetchProductDetails: async (productId) => {
      set(
        produce((draft) => {
          draft.currentProductLoading = true;
          draft.currentProductError = null;
        }),
      );

      const response = await marketplaceAPI.loadMerchantProductDetails(productId);

      console.log("fetchProductDetails response", response);
      if (response.status === 200) {
        set(
          produce((draft) => {
            draft.currentProduct = response?.data;
            draft.currentProductLoading = false;
            draft.currentProductError = null;
          }),
        );
      } else {
        set(
          produce((draft) => {
            draft.currentProductLoading = false;
            draft.currentProductError = response.data;
          }),
        );
      }
    },

    syncAllProducts: async () => {
      set(
        produce((draft) => {
          draft.syncProgress = 0;
        }),
      );

      const response = await marketplaceAPI.syncAllMerchantProducts();

      if (response.status === 200) {
        set(
          produce((draft) => {
            draft.syncJobIds = response?.data?.sync_job_ids;
          }),
        );
      } else {
        set(
          produce((draft) => {
            draft.productsError = response?.data?.errors;
          }),
        );
      }

      return response;
    },

    cancelSyncAllProducts: async () => {
      set(
        produce((draft) => {
          draft.productsLoading = true;
          draft.productsError = null;
        }),
      );

      const response = await marketplaceAPI.cancelSyncAllMerchantProducts();

      if (response.status === 200) {
        set(
          produce((draft) => {
            draft.productsLoading = false;
            draft.productsError = null;
            get().resetSyncProgress();
          }),
        );
      } else {
        set(
          produce((draft) => {
            draft.productsLoading = false;
            draft.productsError = response.data;
          }),
        );
      }

      return response;
    },

    fetchSyncProgress: async () => {
      const response = await marketplaceAPI.loadSyncProgress(get().syncJobIds);

      if (response.status === 200) {
        if (response?.data?.progress == null) {
          set(
            produce((draft) => {
              draft.syncProgress = null;
              draft.syncTotal = null;
              draft.syncAt = null;
              draft.productsError = response?.data?.errors || null;
            }),
          );
          get().resetSyncProgress();
        } else {
          set(
            produce((draft) => {
              draft.syncProgress = response?.data?.progress || 0;
              draft.syncTotal = response?.data?.total || null;
              draft.syncAt = response?.data?.at || null;
              draft.productsError = response?.data?.errors || null;
            }),
          );
        }
      } else {
        set(
          produce((draft) => {
            draft.productsError = response?.data?.errors;
          }),
        );
        get().resetSyncProgress();
      }

      return response;
    },

    resetSyncProgress: () => {
      set(
        produce((draft) => {
          draft.syncProgress = null;
          draft.syncTotal = null;
          draft.syncAt = null;
        }),
      );
    },

    setCurrentProduct: (product) => {
      set(
        produce((draft) => {
          draft.currentProduct = product;
          draft.currentProductLoading = false;
          draft.currentProductError = null;
        }),
      );
    },

    saveMerchantProduct: async (merchantProductId) => {
      set(
        produce((draft) => {
          draft.currentProductLoading = true;
          draft.currentProductError = null;
        }),
      );

      const response = await marketplaceAPI.saveMerchantProduct(merchantProductId);

      if (response.status === 200) {
        set(
          produce((draft) => {
            draft.currentProductLoading = false;
            draft.currentProductError = null;
          }),
        );
        get().fetchProductDetails(merchantProductId);
      }

      return response;
    },

    sendMerchantProductToPim: async (merchantProductId) => {
      const response = await marketplaceAPI.sendMerchantProductToPim(merchantProductId);
      return response;
    },

    createMerchantProductAttribute: async (merchantProductId, payload) => {
      set(
        produce((draft) => {
          draft.currentProductLoading = true;
          draft.currentProductError = null;
        }),
      );

      const response = await marketplaceAPI.createMerchantProductAttribute(
        merchantProductId,
        payload,
      );

      if (response.status === 201) {
        set(
          produce((draft) => {
            draft.currentProductLoading = false;
            draft.currentProductError = null;
          }),
        );
        get().fetchProductDetails(merchantProductId);
      }

      return response;
    },

    removeMerchantProductAttribute: async (merchantProductId, attributeId) => {
      set(
        produce((draft) => {
          draft.currentProductLoading = true;
          draft.currentProductError = null;
        }),
      );

      const response = await marketplaceAPI.removeMerchantProductAttribute(
        merchantProductId,
        attributeId,
      );

      if (response.status === 204) {
        set(
          produce((draft) => {
            draft.currentProductLoading = false;
            draft.currentProductError = null;
          }),
        );
        get().fetchProductDetails(merchantProductId);
      }

      return response;
    },

    createMerchantProductVehicles: async (merchantProductId, vehicleIds) => {
      set(
        produce((draft) => {
          draft.currentProductLoading = true;
          draft.currentProductError = null;
        }),
      );

      const response = await marketplaceAPI.createMerchantProductVehicles(
        merchantProductId,
        vehicleIds,
      );

      if (response.status === 201) {
        set(
          produce((draft) => {
            draft.currentProductLoading = false;
            draft.currentProductError = null;
          }),
        );
        get().fetchProductDetails(merchantProductId);
      }

      return response;
    },

    removeMerchantProductVehicle: async (merchantProductId, vehicleId) => {
      set(
        produce((draft) => {
          draft.currentProductLoading = true;
          draft.currentProductError = null;
        }),
      );

      const response = await marketplaceAPI.removeMerchantProductVehicle(
        merchantProductId,
        vehicleId,
      );

      if (response.status === 200) {
        set(
          produce((draft) => {
            draft.currentProductLoading = false;
            draft.currentProductError = null;
          }),
        );
        get().fetchProductDetails(merchantProductId);
      }

      return response;
    },
  }));
};
