import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link as RouterLink, useParams, useSearchParams } from "react-router-dom";

import { faSearch, faStore, faTruckPlane } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChevronRightIcon } from "@heroicons/react/solid";

import { getShipmentsListQueryOptions, useShipmentsList } from "@/apis/distributor";
import ActionBar from "@/common/components/ActionBar";
import InputField from "@/common/components/InputField";
import NoResults from "@/common/components/NoResults";
import Pagination from "@/common/components/Pagination";
import PartbotIcon from "@/common/components/PartbotIcon";
import StatusBadge from "@/common/components/StatusBadge";

export const loader =
  (queryClient) =>
  async ({ params, request }) => {
    const url = new URL(request.url);
    const page = url.searchParams.get("page") || "1";
    const page_size = url.searchParams.get("page_size") || "1";
    const query = url.searchParams.get("query") || "";
    return await queryClient.ensureQueryData(
      getShipmentsListQueryOptions({
        location_ids: [params.locationId],
        page: page,
        page_size: page_size,
        query: query,
      }),
    );
  };

export default function Bookings() {
  const { locationId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data: { results: shipments, pagination },
    refetch,
  } = useShipmentsList({
    location_ids: [locationId],
    page: searchParams.get("page"),
    page_size: searchParams.get("page_size"),
    query: searchParams.get("query") || "",
  });

  const handleSearch = (searchTerm) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set("query", searchTerm);
      newParams.set("page", "1"); // Reset to first page on new search
      return newParams;
    });
  };

  return (
    <div className="space-y-5">
      <BookingCards
        bookings={shipments}
        locationId={locationId}
        onSearch={handleSearch}
        initialSearchTerm={searchParams.get("query") || ""}
      />
      {shipments?.length >= 1 && <Pagination pagination={pagination} />}
      {shipments?.length === 0 && <NoResults message="No shipments found" />}
    </div>
  );
}

const BookingCards = ({ bookings, locationId, onSearch, initialSearchTerm }) => {
  const { handleSubmit, control } = useForm(
    {
      defaultValues: {
        search: initialSearchTerm,
      },
    },
    { mode: "onBlur" },
  );

  const handleSearchSubmit = (formData) => {
    const searchTerm = formData.search;
    onSearch(searchTerm);
  };

  return (
    <div className="space-y-4">
      <div className="relative w-full">
        <ActionBar className="flex items-center justify-between">
          <div>{/* Bulk Actions */}</div>
          <form onSubmit={handleSubmit(handleSearchSubmit)}>
            <InputField
              name="search"
              label="Search"
              placeholder="Search by order number or tracking number"
              control={control}
              className="!w-96 !py-2"
              icon={<FontAwesomeIcon icon={faSearch} className="text-gray-500" />}
            />
            <button type="submit" className="sr-only">
              Search
            </button>
          </form>
        </ActionBar>
      </div>
      {bookings?.map((booking) => (
        <ShipmentCard key={booking.id} booking={booking} locationId={locationId} />
      ))}
    </div>
  );
};

const ShipmentCard = ({ booking, locationId }) => {
  const containerRef = useRef(null);
  const dangerousGoods = false; // Assuming there's a dangerous_goods field

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const options = { month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="mb-4 grid grid-cols-12 divide-x rounded-lg border border-gray-200 text-sm shadow-sm">
      <div
        className={`col-span-2 flex flex-col justify-around divide-y divide-dashed divide-gray-200 rounded-s-lg bg-gray-50`}
        ref={containerRef}
      >
        <div className="p-2">
          <div className={`text-lg font-semibold leading-tight tracking-wider text-black`}>
            {booking.distributor_reference}
          </div>

          <div className="flex items-center justify-between text-xs font-semibold uppercase tracking-wide text-indigo-500">
            <span>{booking.stores.map((store) => store.name).join(", ")}</span>
            <span className="text-slate-900">{formatDate(booking.created_at)}</span>
          </div>
        </div>
        <div className="space-y-0.5 px-2 py-2 text-xs font-medium uppercase tracking-wide text-slate-900">
          <div className="flex items-center leading-none" title="Partbot Reference">
            <PartbotIcon className="me-1.5 h-3.5 w-4 fill-current text-slate-400" />
            {booking.tracking_reference}
          </div>
          <div className="flex items-center leading-none" title="Store Reference">
            <FontAwesomeIcon icon={faStore} className="me-1.5 h-4 w-4 text-slate-400" />
            {booking.store_reference}
          </div>
          <div className="flex items-center leading-none" title="Freight Broker Reference">
            <FontAwesomeIcon icon={faTruckPlane} className="me-1.5 h-4 w-4 text-slate-400" />
            {booking.freight_broker_reference}
          </div>
        </div>
      </div>
      <div className="relative col-span-10 flex items-start justify-between rounded-e-lg px-4 py-2">
        <div className="flex-1">
          <div className="my-1 font-semibold text-gray-800">Origin</div>
          <div className="uppercase text-gray-600">
            {booking.sender.city} {booking.sender.subdivision_code} {booking.sender.postal_code}
            <br />
            {booking.sender.country}
          </div>
          <div className="text-gray-500">{booking.sender.name}</div>
        </div>
        <div className="flex-1">
          <div className="my-1 font-semibold text-gray-800">Destination</div>
          <div className="uppercase text-gray-600">
            {booking.recipient?.city} {booking.recipient.subdivision_code}{" "}
            {booking.recipient.postal_code}
            <br />
            {booking.recipient.country}
          </div>
          <div className="text-gray-500">{booking.recipient.name}</div>
        </div>
        {/* <div className="flex-1">
          <div className="font-semibold text-gray-800">Carrier</div>
          <div className="text-gray-600">{}</div>
          <div className="text-gray-500">{}</div>
        </div> */}
        <div className="flex-1">
          <div className="my-1 font-semibold text-gray-800">Details</div>
          <div className="text-gray-600">Items: {booking.items_count}</div>
          <div className="text-gray-600">Weight: {booking.total_weight} kg</div>
          {dangerousGoods && <div className="font-semibold text-red-500">Dangerous Goods</div>}
        </div>
        <div className="flex-1 text-right">
          <div as="div" className="absolute bottom-2 right-2 inline-block text-left text-xs">
            <div>
              <RouterLink to={`/locations/${locationId}/shipments/${booking.id}`}>
                <button className="relative inline-flex items-center rounded-md bg-white p-2 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 disabled:cursor-not-allowed disabled:bg-gray-100">
                  <span>Prepare</span>
                  <ChevronRightIcon className="-mr-1 ml-2 h-4 w-4" aria-hidden="true" />
                </button>
              </RouterLink>
            </div>
          </div>
        </div>
        <div className="absolute right-2 top-2">
          <StatusBadge status={booking.status} />
        </div>
      </div>
    </div>
  );
};
