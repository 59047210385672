import React, { useEffect, useState } from "react";
import Select from "react-select";

import { faTrashAlt } from "@awesome.me/kit-989a8e6dbe/icons/classic/light";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button, FormModal, Toggle } from "../../UI/components";
import AttributeCombobox from "../../UI/components/AttributeCombobox";

const DATA_TYPES = [
  { label: "Text", value: "alphanumeric" },
  { label: "Numeric", value: "numeric" },
  { label: "List of options", value: "lookup" },
  { label: "True or false", value: "boolean" },
  { label: "No value", value: "without value" },
];

export default function ProductAttributesModal({
  productAttribute,
  closeModal,
  isOpen,
  loading = false,
}) {
  const [selectedAttributeTitle, setSelectedAttributeTitle] = useState(null);
  const [selectedAttributeValue, setSelectedAttributeValue] = useState(null);
  const [valueOptions, setValueOptions] = useState([]);
  const [valueOptionsLoading, setValueOptionsLoading] = useState(false);
  const [valueInput, setValueInput] = useState("");
  const [selectedProductAttribute, setSelectedProductAttribute] = useState(null);

  const [showNewAttributeForm, setShowNewAttributeForm] = useState(false);

  useEffect(() => {
    if (productAttribute && productAttribute.title_id) {
      setSelectedProductAttribute(productAttribute);
      setSelectedAttributeTitle({
        id: productAttribute.title_id,
        name: productAttribute.title,
        data_type: productAttribute.data_type,
      });

      console.log("productAttribute", productAttribute.data_type);
      if (productAttribute.data_type === "lookup") {
        setValueInput("");
        setSelectedAttributeValue({ label: productAttribute.value });

        // fetch values for attribute
        fetchAttributeValues({ id: productAttribute.title_id }).then((values) => {
          // map the returned values, return all but set label the same as value
          const options = values.map((v) => {
            return { ...v, label: v.value };
          });
          setValueOptions(options);
        });
      } else {
        // set to free input, prefill with value
        console.log("productAttribute.data_type", productAttribute.data_type);
        console.log("productAttribute.value", productAttribute.value);

        // setValueInput to productAttribute.value, convert string to number if data_type is numeric
        setValueInput(
          productAttribute.data_type === "numeric"
            ? parseFloat(productAttribute.value)
            : productAttribute.value,
        );
      }
    } else if (productAttribute) {
      // show a creation form instead
      console.log("we will create a new attribute for", productAttribute);
    }
  }, [productAttribute, isOpen]);

  useEffect(() => {
    if (selectedAttributeTitle) {
      setValueOptions(selectedAttributeTitle.values);
    }
  }, [selectedAttributeTitle]);

  const onSubmit = () => {
    console.log("onSubmit", selectedAttributeTitle, selectedAttributeValue, valueInput);
    const value = {
      attribute_title_id: selectedAttributeTitle.id,
      attribute_value: valueInput && valueInput !== "" ? valueInput : selectedAttributeValue?.label,
    };

    resetFields();
    closeModal(value);
  };

  const onCancel = () => {
    closeModal(null);
    resetFields();
  };

  const resetFields = () => {
    setTimeout(() => {
      setSelectedAttributeTitle(null);
      setSelectedAttributeValue(null);
      setSelectedProductAttribute(null);
      setValueOptions([]);
      setValueInput("");
    }, 500);
  };

  const cache = {};
  const fetchAttributeValues = async (attribute) => {
    setValueOptionsLoading(true);
    if (cache[attribute.id]) {
      setValueOptionsLoading(false);
      return Promise.resolve(cache[attribute.id]);
    }
    return fetch(`/attribute/${attribute.id}/values`)
      .then((res) => res.json())
      .then((result) => {
        cache[attribute.id] = result;
        setValueOptionsLoading(false);
        return result;
      });
  };

  function onAttributeChange(attribute) {
    setSelectedAttributeTitle(attribute);
    setSelectedAttributeValue(null);
    setValueOptions(null);
    setValueInput("");

    console.log("onAttributeChange", attribute);

    // if attribute.id is null, we need to create a new attribute
    if (attribute && attribute.id === null) {
      console.log("we will create a new attribute for", attribute);

      setShowNewAttributeForm(true);
      return;
    }

    if (attribute && attribute.data_type === "lookup") {
      fetchAttributeValues(attribute).then((values) => {
        // map the returned values, return all but set label the same as value
        const options = values.map((v) => {
          return { ...v, label: v.value };
        });
        setValueOptions(options);
      });
    } else if (attribute && attribute.data_type === "without value") {
      // default to true?
    }
  }

  // create new attribute
  const createNewAttribute = async ({ attributeTitle, dataType, values }) => {
    return fetch(`/admin/attributes`, {
      method: "POST",
      headers: {
        ...ReactOnRails.authenticityHeaders(),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        attribute_title: {
          name: attributeTitle.name,
          data_type: dataType,
          values: values,
        },
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("createNewAttribute result:", result);
        // Transform the response data to match the expected format
        const formattedTitle = {
          id: result.id,
          name: result.name,
          data_type: result.data_type,
        };
        setSelectedAttributeTitle(formattedTitle);
        setShowNewAttributeForm(false);

        if (result.data_type === "lookup") {
          fetchAttributeValues(formattedTitle).then((values) => {
            const options = values.map((v) => ({
              ...v,
              label: v.value,
            }));
            setValueOptions(options);
          });
        }

        return result;
      });
  };

  return (
    <FormModal
      title={`${selectedProductAttribute ? "Edit" : "Add"} Attribute`}
      {...{ onCancel, isOpen }}
    >
      <div className="my-4">
        <AttributeCombobox
          selectedOption={selectedAttributeTitle}
          handleAttributeSelect={(e) => {
            onAttributeChange(e);
          }}
        />
      </div>

      {/* Show New Atribute Form */}
      {showNewAttributeForm && (
        <div>
          <NewAttributeForm
            attribute={selectedAttributeTitle}
            onSubmit={({ data_type, values }) => {
              createNewAttribute({
                attributeTitle: selectedAttributeTitle,
                dataType: data_type,
                values: values,
              });
            }}
            onCancel={() => {
              setShowNewAttributeForm(false);
            }}
          />
        </div>
      )}

      {/* Show Loading Animation */}
      {!showNewAttributeForm && valueOptionsLoading && (
        <div className="my-4">
          <label>Value</label>
          <div className="h-[40px] w-full animate-pulse rounded bg-gray-200"></div>
        </div>
      )}

      {/* Show Lookup Selector if selectedAttributeTitle.data_type === "lookup" */}
      {!showNewAttributeForm &&
        selectedAttributeTitle &&
        selectedAttributeTitle?.data_type === "lookup" &&
        !valueOptionsLoading &&
        valueOptions &&
        valueOptions?.length > 0 && (
          <div className="my-4">
            <label>Value</label>
            <Select
              className="!text-sm"
              defaultValue={selectedAttributeValue}
              onChange={setSelectedAttributeValue}
              options={valueOptions}
            />
          </div>
        )}

      {/* Show Free Entry input */}
      {!showNewAttributeForm &&
        selectedAttributeTitle &&
        ["alphanumeric", "numeric"].includes(selectedAttributeTitle.data_type) && (
          <div className="my-4">
            <label>Value</label>
            <input
              type="text"
              onInput={(e) =>
                (e.target.value = selectedAttributeTitle.tec_doc_max_length
                  ? e.target.value.slice(0, selectedAttributeTitle.tec_doc_max_length)
                  : e.target.value)
              }
              // only add maxLength attribute if selectedAttributeTitle.tec_doc_max_length is not null
              {...(selectedAttributeTitle.tec_doc_max_length
                ? { maxLength: selectedAttributeTitle.tec_doc_max_length }
                : {})}
              className="form-input"
              value={valueInput}
              step="0.01"
              onChange={(event) => {
                const newValue = event.target.value;
                if (
                  (selectedAttributeTitle.data_type === "numeric" &&
                    /^-?\d*\.?\d*$/.test(newValue)) ||
                  selectedAttributeTitle.data_type !== "numeric"
                ) {
                  setValueInput(newValue);
                }
              }}
            />
            <div className="mt-1.5 flex w-full items-center justify-between">
              <div className="text-xs capitalize text-gray-500">
                {selectedAttributeTitle.data_type}
              </div>
              {selectedAttributeTitle.tec_doc_max_length && (
                <div className="text-xs text-gray-500">
                  {valueInput.length}/{selectedAttributeTitle.tec_doc_max_length} characters
                </div>
              )}
            </div>
          </div>
        )}

      {/* Show True/False Selector */}
      {!showNewAttributeForm &&
        selectedAttributeTitle &&
        selectedAttributeTitle.data_type === "boolean" && (
          <div className="my-4">
            <div className="flex items-center">
              <Toggle
                checked={valueInput === "Yes"}
                onChangeCallback={(event) => {
                  // return if event is not true or false
                  if (typeof event !== "boolean") return;

                  // check if event is true or false, set valueInput to "Yes" or "No"
                  setValueInput(event ? "Yes" : "No");
                }}
                showLabel={false}
              />
            </div>
            <div className="mt-1.5 flex w-full items-center justify-between">
              <div className="text-xs capitalize text-gray-500">
                {selectedAttributeTitle.data_type}
              </div>
            </div>
            <div className="mt-3 text-xs text-indigo-500">
              Attribute will display as "Yes" or "No"
            </div>
          </div>
        )}

      {!showNewAttributeForm && selectedAttributeTitle && (
        <div className="btn-group mt-4 flex justify-end">
          <button type="button" className="btn btn-neutral" onClick={onCancel}>
            Cancel
          </button>
          <Button
            label={
              // if selectedProductAttribute exists, set the label to either "Save" or "Saving" if loading is true, otherwise set the label to "Add" or "Adding"
              selectedProductAttribute
                ? loading
                  ? "Saving..."
                  : "Save"
                : loading
                  ? "Adding..."
                  : "Add"
            }
            showLoading={loading}
            type="button"
            className="btn"
            onClick={onSubmit}
          />
        </div>
      )}
    </FormModal>
  );
}

// create a NewAttributeForm component
const NewAttributeForm = ({ onSubmit, onCancel }) => {
  const [attributeDataType, setAttributeDataType] = useState(DATA_TYPES[0].value);
  const [attributeValues, setAttributeValues] = useState([]);
  const [attributeValueInput, setAttributeValueInput] = useState("");

  const [loading, setLoading] = useState(false);

  const handleAddValue = () => {
    setAttributeValues([...attributeValues, attributeValueInput]);
    setAttributeValueInput("");
  };

  const handleRemoveValue = (value) => {
    setAttributeValues(attributeValues.filter((v) => v !== value));
  };

  const handleSubmit = () => {
    setLoading(true);
    onSubmit({
      data_type: attributeDataType,
      values: attributeValues,
    });
  };

  return (
    <div>
      <div className="my-4">
        <label>Data Type</label>
        <select
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          value={attributeDataType}
          onChange={(event) => setAttributeDataType(event.target.value)}
        >
          {DATA_TYPES.map((dataType) => (
            <option key={dataType.value} value={dataType.value}>
              {dataType.label}
            </option>
          ))}
        </select>
      </div>

      {attributeDataType === "lookup" && (
        <div className="my-4">
          <label>Values</label>
          <div className="flex items-center">
            <input
              ref={(input) => input && input.focus()}
              type="text"
              className="form-input"
              value={attributeValueInput}
              onChange={(event) => setAttributeValueInput(event.target.value)}
            />
            <button
              type="button"
              className="btn btn-primary ml-2"
              onClick={handleAddValue}
              disabled={attributeValueInput === ""}
            >
              Add
            </button>
          </div>
          <div className="my-4">
            {attributeValues.map((value) => (
              <div
                key={value}
                className="flex items-center justify-between gap-2 border-b border-gray-200 py-2"
              >
                <div className="text-xs">{value}</div>
                <button
                  type="button"
                  className="btn btn-sm btn-danger"
                  onClick={() => handleRemoveValue(value)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} className="h-3 w-3" />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="btn-group mt-4 flex justify-end">
        <button type="button" className="btn btn-neutral" onClick={onCancel}>
          Cancel
        </button>
        <Button
          label={loading ? "Saving..." : "Create Attribute"}
          showLoading={loading}
          type="button"
          className="btn"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};
