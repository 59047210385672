// InputField.tsx
import React, { ReactElement } from "react";
import { Controller, Control, FieldErrors, useForm } from "react-hook-form";
import { faTimes } from "@awesome.me/kit-989a8e6dbe/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "../utils/classNames";

interface InputFieldProps {
  name: string;
  type: string;
  control: Control<any>;
  placeholder?: string;
  className?: string;
  onValueChange: (value: string) => void;
  icon?: ReactElement;
  iconPosition?: "start" | "end";
  clearable?: boolean;
}

const InputField: React.FC<InputFieldProps> = ({
  name,
  control,
  type = "text",
  placeholder = "",
  className = "",
  onValueChange,
  icon,
  iconPosition = "start",
  clearable = false,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const handleClear = () => {
          field.onChange("");
          onValueChange && onValueChange("");
        };

        return (
          <div className="relative">
            {icon && iconPosition === "start" && (
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                {icon}
              </div>
            )}
            <input
              {...field}
              type={type}
              onChange={(e) => {
                field.onChange(e);
                onValueChange && onValueChange(e.target.value);
              }}
              placeholder={placeholder}
              className={cn(
                "block w-full rounded-md !border-slate-300 shadow-sm sm:!text-xs",
                icon && iconPosition === "start" && "pl-10",
                icon && iconPosition === "end" && "pr-10",
                field.value && "pr-8", // Add padding for the clear button
                fieldState.isDirty && !fieldState.invalid && "!border-amber-500",
                fieldState.invalid && "!border-rose-500",
                className,
              )}
            />
            {icon && iconPosition === "end" && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                {icon}
              </div>
            )}
            {clearable && field.value && (
              <button
                type="button"
                onClick={handleClear}
                className="absolute inset-y-0 right-0 flex items-center pr-3"
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  className="h-5 w-5 text-gray-400 hover:text-gray-600"
                />
              </button>
            )}
          </div>
        );
      }}
    />
  );
};

export default InputField;
