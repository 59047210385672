import React from "react";

import { cn } from "../utils/classNames";

const colorVariants = {
  green: "bg-green-200 text-green-900",
  blue: "bg-blue-200 text-blue-900",
  gray: "bg-gray-200 text-gray-700",
  yellow: "bg-yellow-200 text-yellow-900",
  purple: "bg-purple-200 text-purple-900",
  red: "bg-red-200 text-red-900",
};

const defaultStatusColorMap = {
  pending: "yellow",
  quoted: "blue",
  booked: "purple",
  in_progress: "purple",
  "in transit": "purple",
  delivered: "green",
  cancelled: "gray",
  complete: "green",
  cancellation_accepted: "gray",
  cancellation_rejected: "red",
};

const humanize = (str) => {
  return str.replace(/_/g, " ");
};

const StatusBadge = ({ status, customStatusColorMap = {}, className }) => {
  const getStatusColor = (status) => {
    const lowercaseStatus = status?.toLowerCase();
    const colorKey =
      customStatusColorMap[lowercaseStatus] || defaultStatusColorMap[lowercaseStatus] || "yellow";
    return colorVariants[colorKey] || colorVariants.yellow;
  };

  return (
    <span
      className={cn(
        "inline-block rounded px-2 py-1 text-xs font-semibold capitalize leading-none",
        getStatusColor(status),
        className,
      )}
    >
      {humanize(status)}
    </span>
  );
};

export default StatusBadge;
